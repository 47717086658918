@font-face {
    font-family: "Montserrat";
    src: local("Montserrat Light"),
        url("./fonts/Montserrat-Light.ttf") format("truetype");
    font-weight: 100;
}

@font-face {
    font-family: "Montserrat";
    src: local("Montserrat Regular"),
        url("./fonts/Montserrat-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "Montserrat";
    src: local("Montserrat Bold"),
        url("./fonts/Montserrat-Bold.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: "Montserrat";
    src: local("Montserrat ExtraBold"),
        url("./fonts/Montserrat-ExtraBold.ttf") format("truetype");
    font-weight: 800;
}

@font-face {
    font-family: 'Yahoo Sans';
    src: url('./fonts/YahooSans-Regular.woff2') format('woff2'),
        url('./fonts/YahooSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Yahoo Sans';
    src: url('./fonts/YahooSans-Light.woff2') format('woff2'),
        url('./fonts/YahooSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Yahoo Sans';
    src: url('./fonts/YahooSans-Bold.woff2') format('woff2'),
        url('./fonts/YahooSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Yahoo Sans';
    src: url('./fonts/YahooSans-Medium.woff2') format('woff2'),
        url('./fonts/YahooSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Anton&family=Bad+Script&family=Bebas+Neue&family=Benne&family=Concert+One&family=Dancing+Script&family=Fredoka+One&family=Gruppo&family=Inconsolata:wght@300&family=Josefin+Slab&family=Lato:wght@300&family=Montserrat:wght@300&family=Open+Sans+Condensed:wght@300&family=PT+Mono&family=Roboto:wght@300&family=Slabo+27px&display=swap');
