.splashmob-loading-screen {
    justify-content: center;
    align-items: center;
}

.splashmob-loading-screen .loading-icon {
    width:50px;
}

.loading-icon {
    animation: loading-spin 2s infinite;
}

@keyframes loading-spin {
    0% {
        transform: rotate(0);
        animation-timing-function: ease-in;
    }
    50% {
        transform: rotate(360deg);
        animation-timing-function: ease-out;
    }
    100% {
        transform: rotate(720deg);
    }
}
